import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import PolicySEO from "../components/shared/policySEO"
import PolicyContent from "../components/privacy/policyContent"

const WebsiteDisclaimer = () => {

  const { sanityWebsiteDisclaimer } = useStaticQuery(graphql`
  {
    sanityWebsiteDisclaimer {
      policyContent {
        _rawContent
      }
          seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
    }
  }
  `)


  return (
    <div className="subpage-container">
      <PolicySEO seoInformation={sanityWebsiteDisclaimer.seo} />
      <PolicyContent policyContent={sanityWebsiteDisclaimer.policyContent} policyName="General Website Disclaimer" />
    </div>
  )
}

export default WebsiteDisclaimer
